import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Modal } from '@clarke-energia/foton';

import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';

import { ILeadPayLoad } from '@contexts/lead/types';

interface IModalRegisterNewLead {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateLead: (name: string) => Promise<void>;
}

const ModalRegisterNewLead = ({
  isModalOpen,
  setIsModalOpen,
  handleCreateLead,
}: IModalRegisterNewLead): JSX.Element => {
  const formController = useForm<ILeadPayLoad>();
  const { handleSubmit } = formController;

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCreateLeadOnClick: SubmitHandler<ILeadPayLoad> = async ({ name }) => {
    setLoading(true);
    if (name.includes('/')) {
      alert('O nome do lead não pode conter o caractere barra (/)');
      setLoading(false);
      return;
    }
    handleCreateLead(name).finally(() => setLoading(false));
  };

  return (
    <Modal
      className="md:w-96 md:h-[22.25rem]"
      open={isModalOpen}
      setOpen={() => setIsModalOpen(false)}
      title="Novo Lead"
      description="Não encontrou o grupo comercial que procura? Cadastre-o aqui."
      modalButtons={{
        primary: {
          label: 'Cadastrar',
          type: 'submit',
          style: { backgroundColor: 'primary', width: '100%' },
          onClick: handleSubmit(handleCreateLeadOnClick),
          loading,
          disabled: loading,
        },
        secondary: { label: 'Cancelar', style: { width: '100%' }, onClick: () => setIsModalOpen(false) },
      }}
    >
      <FormProvider {...formController}>
        <form>
          <div>
            <GenericFormField<ILeadPayLoad>
              id="name"
              field="name"
              label="Grupo Comercial"
              placeholder="Nome do lead ou cliente"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ModalRegisterNewLead;
